header{
    color: white;
    display: flex;
    justify-content: space-between;
}
.titulos{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: auto;
}
/* .titulos h1{
    overflow: hidden;
}
h1::after{
    background-color: #ffc107;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
}
.titulos >h1::after{
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
}

.titulos h1:hover::after,
.titulos h1:focus::after{
  transform: translate3d(0, 0, 0);
} */

/* .typed-container {
    display: inline-block;
}

.typed-out {
    overflow: hidden;
    border-right: .15em solid orange;
    font-size: 1.6rem;
    width: 0;
    animation: typing 1s steps(30,end) forwards;
}


@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
} */