@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');

*{
  font-family: 'Varela Round', sans-serif;

}
.App {
  text-align: center;
  background-color: black;
}

h2{
  margin: 50px 0px 50px 0px !important;
}


@media (max-width:600px) {
  .perfil{
    display: none;
  }
}


/* section{
  height: 80vh;
} */
/* 
section #header, #about{
  height: 50%;
} */