

ul{
    list-style: none;
    display: flex;
    flex-direction: row;
    background-color: #ffc107;
    justify-content: space-around;
    padding: 20px 10px;
}

ul a{
    text-decoration: none;
    color: black;
    font-family: 'Varela Round', sans-serif;
}