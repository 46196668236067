section{
    color: white;
}

.about p{
    width: 80%;
}

.about-cont{
    text-align: center;
}